
import nosotros from '../estilos/nosotros.module.css';
import { useSelector } from "react-redux";



function SobreNosotros() {
    const seleccion = useSelector((state) => state.counter.parametrosDeBusqueda);

    return (
        <div className={seleccion.modus ? nosotros.light : nosotros.dark }>
        
        <div className={nosotros.portada}>

            <h1>Quienes somos</h1>
            <img title="vivir en Alemania" alt="vivir en Alemania" src="https://desplora.com/medien/Aida_y_Diego.webp"/>
        </div>

        <p className={nosotros.textoPrincipal}>
        ¡Hola! Somos Diego y Aida, dos aventureros apasionados por los viajes.<br/><br/>
Nos encanta descubrir nuevos lugares, culturas y experiencias. Desde las bulliciosas 
ciudades hasta los rincones más remotos del planeta, siempre estamos buscando la próxima 
aventura.
<br/><br/>
En nuestro blog, "Viajando con Diego y Aida", compartimos nuestras experiencias viajeras 
con el mundo. A través de fotos, videos y relatos, queremos inspirar a otros a explorar 
el mundo y descubrir la belleza que este tiene para ofrecer.
<br/><br/>
Creemos que viajar es una de las mejores formas de aprender y crecer como personas. 
Cada viaje nos abre la mente a nuevas ideas y nos permite conocer gente maravillosa 
de diferentes culturas.
<br/><br/>
Si tú también eres un amante de los viajes, te invitamos a unirte a nuestra comunidad. 
Comparte tus experiencias, consejos e historias en nuestro blog. ¡Juntos podemos 
recorrer el mundo!
        </p>
      

    </div>
    );
}


export default SobreNosotros;   