import { useCookies } from 'react-cookie';
import KookiesStyle from '../estilos/cookies.module.css';
function Cookies({modus}) {


     //cookies
     const [cookies, setCookie] = useCookies(['Concentimiento']);

        const aceptarCookies = () => {

            setCookie('Concentimiento', 'true', {
                path: '/',
                maxAge: 30 * 24 * 60 * 60
              });

        };

       const handleAceptarHover = () =>{
              document.getElementById('avionVolando').style.transform = 'rotate(-10deg)';
              document.getElementById('avionVolando').style.top = '-160px';
       }    

       const handleEscencialesHover = () =>{
        document.getElementById('avionVolando').style.transform = 'rotate(10deg)';
        document.getElementById('avionVolando').style.top = '-110px';
    } 

        const handleVerCookiesHover = () =>{
            document.getElementById('avionVolando').style.transform = 'rotate(0deg)';
            document.getElementById('avionVolando').style.top = '-130px';
        } 

        const handleVerCookies = () =>{
            document.getElementById('verMisCookies').style.transform = 'translateY(0)';
            handleAceptarHover();
        }

        

    return (
        <div className={`${!cookies.Concentimiento? KookiesStyle.CookiecontPrincipal : KookiesStyle.NoCookie}` }>
        <div className={KookiesStyle.Cookiecont}>
            <div className={KookiesStyle.contenedor}>
                <h3>Solo usamos Cookies seguras</h3>
                <p className={KookiesStyle.texto}>"¡Advertencia! Estas cookies no te llevarán a destinos exóticos, pero harán que tu navegación sea más sabrosa que un viaje en primera clase." 🍪✈️</p>
            <button onMouseEnter={handleAceptarHover} onMouseLeave={handleVerCookiesHover} className={KookiesStyle.aceptar} onClick={aceptarCookies}>Aceptar</button>
            <button onMouseEnter={handleEscencialesHover} onMouseLeave={handleVerCookiesHover} className={KookiesStyle.escenciales} onClick={aceptarCookies}>Solo escenciales</button>
            <button onMouseEnter={handleVerCookiesHover} className={KookiesStyle.verMisCookies} onClick={handleVerCookies}>Mis Cookies</button>
            </div>
            <iframe title='avión desplora' id="avionVolando" className={KookiesStyle.lottie} src="https://lottie.host/embed/44576d81-6bb8-4277-9e36-c83ce6b34321/ackFyyHjRU.json"></iframe>
        </div>
        <div id='verMisCookies' className={KookiesStyle.VerCookieInfo}>
            <h3>Nuestra política de Cookies</h3>
            <p>De tu visita guardamos las siguientes infos:</p>
            <ul>
                <li>Visita</li>
                <li>Desde dónde nos visitas</li>
                <li>Qué destinos te han gustado más</li>
                <li>Qué tipo de cookies has elegido</li>
                <li>Si has aceptado las cookies</li>
            </ul>

            <img src="https://desplora.com/medien/LOGO_DESPLORA.webp" alt="logo" />
            
        </div>
        </div>
    );
}



export default Cookies;