import React, {useState} from 'react';
import home from '../estilos/home.module.css';
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import { addCiudad, addId } from "../data/operaciones";
import { FaPlaneDeparture } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";


import TextoPrincipal from '../componentes/textoPrincipal';

import scrollToTop from '../componentes/scrollToTop';


function Home(){

  const seleccion = useSelector((state) => state.counter.parametrosDeBusqueda);
  const dispatch = useDispatch();

  let recomendados = seleccion.contenido.map((obj)=> obj)

        function setPamLink(pam1, pam2){
          scrollToTop()
          dispatch(addId(pam1))
          dispatch(addCiudad(pam2))
          
        }

  let paginaHome = seleccion.paginasInfo[0]

    return (
      <div className={`${seleccion.modus ? home.light : home.dark}`} >

            <Helmet>
                <title>desplora</title>
                <meta name="description" content="Blog de viajes con informacion sobre viajes a destinos interesantes en todo el mundo, Viajes
                                                  Destinos turísticos, Vacaciones, Turismo, Reservas de hotel, Vuelos baratos, 
                                                  Paquetes turísticos, Alojamiento, Viajes en familia, Viajes de aventura, 
                                                  Guía de viaje, Consejos de viaje, Experiencias de viaje, Viajes económicos, 
                                                  Viajes culturales, Viajes gastronómicos, Transporte turístico, Viajes de fin 
                                                  de semana, Destinos exóticos, Viajes en tren, Actividades turísticas, Viajes 
                                                  de mochilero, Viajes de playa" />
                <link rel="canonical" href="https://desplora.com/"/>
            </Helmet>
            

      <div className={home.portada}>
      <h1 className={home.tituloPrincipal}>DES<span style={{opacity: "0.4"}}>CUBRE & EX</span>PLORA</h1>
      <h2>Te contamos todo sobre tu próximo destino</h2>
      <img title='portada desplora.com' alt='Avion para volar con desplorando' src="https://desplora.com/medien/desplora portada.webp" className={home.fondoImagenPortada}/>
      </div>
      <br/>
      {paginaHome ? <TextoPrincipal contenidoPayload={paginaHome.texto_introduccion} modus={seleccion.modus}/> : "nada por aqui"}
      <br/><br/><br/>
      <h3 className={home.tituloInformacinoH4}><FaPlaneDeparture className={home.iconStandard} /> Destinos que te podrían interesar</h3>
      <br/>

      {!seleccion.continente?
            
            <div className={home.riel}>
            {recomendados.map((ciudad, index)=>(

              <NavLink to={`/destinos/${ciudad.id}`} onClick={()=>setPamLink(ciudad.id, ciudad.titulo)} className={home.navlink} key={ ciudad.titulo + index}>
                <div className={home.miniaturaHome}>
                    <div className={home.info}>
                      <p className={home.titulo}>{ciudad.titulo}</p>
                      <p className={home.pais}>{ciudad.pais}</p>
                    </div>
                    <img title={`Quieres saber más sobre ${ciudad.titulo}?`} loading='lazy' className={home.fondoImagen} src={`https://desplora.com/medien/${ciudad.imagenPrincipal}.webp`}/>
                </div>
              </NavLink>
            ))}
          </div> : "nada por aqui"
        }
      <br/><br/>
      {paginaHome ? <TextoPrincipal contenidoPayload={paginaHome.texto_opcional} modus={seleccion.modus}/> : "nada por aqui"}

      <br/><br/>
      </div>
    )
  }

  export default Home;













