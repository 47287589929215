import { Helmet } from "react-helmet-async";
import vidaenlemania from '../estilos/vidaenlemania.module.css'
import { useSelector } from "react-redux";
import TextoPrincipal from '../componentes/textoPrincipal';
import TablaDeContenido from '../componentes/TablaDeContenido';




function VidaEnAlemania() {

  const seleccion = useSelector((state) => state.counter.parametrosDeBusqueda);


    <Helmet>
        <title>vivir en Alemania</title>
        <meta name="description" content="como vivir en alemania" />
        <link rel="canonical" href="/vidaenlemania"/>
    </Helmet>

    const vidaenlemaniaPage = seleccion.paginasInfo[2]


  return (
    
    
    <div className={seleccion.modus ? vidaenlemania.light : vidaenlemania.dark }>


          



        
        <div className={vidaenlemania.portada}>

            <h1>Vida en Alemania</h1>
            <img title="vivir en Alemania" alt="vivir en Alemania" src="https://desplora.com/medien/vivir_en_alemania.webp"/>
        </div>

        {vidaenlemaniaPage ? <TextoPrincipal contenidoPayload={vidaenlemaniaPage.texto_introduccion} modus={seleccion.modus}/> : "nada por aqui"}
      
        <TablaDeContenido/>

    </div>
  );
}

export default VidaEnAlemania;