function scrollToTop() {
    // Si el navegador soporta la API ScrollToOptions
    if ("scrollBehavior" in document.documentElement.style) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      // Para navegadores más antiguos
      window.scrollTo(0, 0);
    }
  }

  export default scrollToTop;