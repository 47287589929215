import { useEffect } from 'react';
import { useState } from 'react';
import TablaStyle from '../estilos/TablaStyle.module.css'
import { NavLink } from 'react-router-dom';
import scrollToTop from '../componentes/scrollToTop';



function TablaDeContenido(){

    const [categoria, setCategoria] = useState("")
    const [articulos, setArticulos] = useState([])
    const [categoriasArr, setCategoriasArr] = useState([])
    
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://diegobarakus.github.io/apiAlimantador/vidaEnAlemania.json');
          if (!response.ok) {
            throw new Error('Failed to fetch');
          }
          const jsonData = await response.json();
          setArticulos(jsonData);
          
          let arrUnico = []
          jsonData.forEach(element => {
            element.categoria.forEach(categoria => {
              if(!arrUnico.includes(categoria)){
                arrUnico.push(categoria)
              }
            })
          });

          setCategoriasArr(arrUnico)
       
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);

   
    
      return(

        <>
        
        <div className={TablaStyle.CategoriaSlide}>
        <ul>
        {categoriasArr.map( item =>{
          return <li>{item}</li>
        })}
        </ul>
      </div>
      
        <div className={TablaStyle.light}>

            {articulos.map((articulo, index) => {
                return(
                  <NavLink onClick={()=>{scrollToTop()}} to={`${articulo.titulo}`}>
                    <div key={index} className={TablaStyle.articulo}>
                      <div className={TablaStyle.articuloInfo}>
                        <h3>{articulo.titulo}</h3>
                        <p>{articulo.texto? `${articulo.texto[0].slice(0, 60)}...`: "Ver más"}</p>
                        </div>
                        <img className={TablaStyle.articuloImg} src={`https://desplora.com/medien/${articulo.imagen}.webp`} />
                    </div>
                    </NavLink>
                )
            })}
        </div>
        </>
        )
    }

export default TablaDeContenido;