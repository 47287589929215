
import { DuffelAncillaries } from '@duffel/components'

function ReservaVuelos(){



    return(
        <div>
            <h1>Reserva de Vuelos</h1>
        </div>
    )
}


export default ReservaVuelos;