import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TextoPrincipal from '../componentes/textoPrincipal';
import { useEffect, useState } from "react";
import articuloStyle from '../estilos/articulo.module.css';



function Articulo() {

    const seleccion = useSelector((state) => state.counter.parametrosDeBusqueda);


    let { pathArticulo } = useParams();
    const [articulo, setArticulo] = useState({})

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://diegobarakus.github.io/apiAlimantador/vidaEnAlemania.json');
          if (!response.ok) {
            throw new Error('Failed to fetch');
          }
          const jsonData = await response.json();
          
          let articuloEncontrado = jsonData.find(articulo => articulo.titulo === pathArticulo)
          setArticulo(articuloEncontrado);

       
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);




  return (
    <div className={`${seleccion.modus ? articuloStyle.light : articuloStyle.dark}`}>
      <div className={articuloStyle.portada}>

        <h1>{articulo.titulo}</h1>
        <img title="vivir en Alemania" alt="vivir en Alemania" src={`https://desplora.com/medien/${articulo.imagen}.webp`}/>
        </div>
      {articulo.texto ? <TextoPrincipal contenidoPayload={articulo.texto} modus={seleccion.modus} /> : "nada por aqui"}

      <h1>Experiencia</h1>
      {articulo.experiencia ? <TextoPrincipal contenidoPayload={articulo.experiencia} modus={seleccion.modus} /> : "nada por aqui"}
    </div>
  );
}


export default Articulo;