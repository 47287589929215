import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import ContactStyle from '../estilos/contacto.module.css';

function Contacto(){
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0zpjwnc', 'template_ctpnio4', form.current, {
        publicKey: 'NomqfsFC7UL0Sg7On',
      })
      .then(
        () => {
            document.getElementById('inputName').value = '';
            document.getElementById('inputEmail').value = '';
            document.getElementById('inputText').value = '';
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className={ContactStyle.light}>
      <div className={ContactStyle.barra}></div>
    <form ref={form} onSubmit={sendEmail}>
      <div className={ContactStyle.infoInput}>
      <label>Name: </label>
      <input id="inputName" type="text" name="user_name" />
      </div>

      <div className={ContactStyle.infoInput}>
      <label>Email: </label>
      <input id="inputEmail" type="email" name="user_email" />
      </div>

      <div className={ContactStyle.infoInput}>
      <label>Message: </label>
      <textarea id="inputText" name="message" />
      </div>
      <input className={ContactStyle.submit} type="submit" value="Send" />
    </form>
    <div className={ContactStyle.barra}></div>
    </div>
  );
};

export default Contacto;