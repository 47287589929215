import foquiNavega from '../estilos/foquitaNavega.module.css'
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import {  useSelector, useDispatch } from "react-redux";
import { addBusqueda, addCiudad } from "../data/operaciones";
import { Link } from 'react-router-dom'
import { MdDelete } from "react-icons/md";
import { useState } from 'react';






function FoquitaNavega(){

    const [busqueda, setBusqueda] = useState("");

    const goBack = () => {
        window.history.back(); // Utiliza window.history para ir a la página anterior
      };

    const seleccion = useSelector((state) => state.counter.parametrosDeBusqueda);
    const dispatch = useDispatch();

     const handleChange = (e) => {
      dispatch(addBusqueda(e.target.value))
      setBusqueda(seleccion.addBusqueda)
    };


    const handleDelete = () => {
        dispatch(addBusqueda("")); // Actualiza el valor de búsqueda a una cadena vacía
        setBusqueda(""); // Actualiza el estado local a una cadena vacía
    };

    

    return(
        <div className={foquiNavega.contenedor}>

            <div className={foquiNavega.contInputDelete}>
            <Link className={foquiNavega.inputLink} to="/busqueda">
            <input id="inputBusqueda" onChange={handleChange} className={foquiNavega.inputBusqueda} placeholder='Busca tu próximo destino' value={busqueda} type='text' />
            </Link>
            {seleccion.busquedaActual.length > 0 ?
                <MdDelete onClick={handleDelete} size={30} className={foquiNavega.deleteIcon} />
                : ""}
            </div>

        </div>
    )
}

export default FoquitaNavega;